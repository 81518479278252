.main-container {
    overflow-y: auto;
    width: 100%;
}

.chatbot-container-root {
    display: none;
    width: 0%;
    transition: all 0.3s ease;
}

.expanded .main-container {
    width: 70%;
}

.expanded .chatbot-container-root {
    display: block;
    width: 30%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.3s ease;


    // background: linear-gradient(316deg, #FFF 0%, #F0F0F0 57.36%);
    // background: red;
    background: linear-gradient(316deg, #FFF 0%, #F0F0F0 57.36%);
    box-shadow: 0px 0px 1.5399999618530273px 0px rgba(0, 0, 0, 0.12), 0px 0.7699999809265137px 1.5399999618530273px 0px rgba(0, 0, 0, 0.14);
}

.expand-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    padding: 10px;
    font-size: 16px;
}
  