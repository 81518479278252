.sustainability-report-menu-wrapper.shadow {
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.1);
}
.sustainability-report-wrapper .two-line-value-and-unit {
  flex-direction: column;
  >span {
    margin-bottom: 0;
  }
  span {
    align-self: center;
  }
}
@media only screen and (max-width: 1024px) {
  .sustainability-report-header-wrapper {
    display: flex;
    .sustainability-report-menu-icon {
      display: block;
    }
  }
  .sustainability-report-menu-wrapper {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .sustainability-report-header-wrapper {
    display: flex;
    .sustainability-report-menu-icon {
      display: block;
    }
  }
  .sustainability-report-menu-portfolio-overview {
    flex-direction: row;
    gap: 0.5rem;
  }
  .sustainability-report-menu-wrapper {
    display: none;
  }
  .sustainability-report-content-wrapper {
    padding-right: 5%;
    padding-top: 7.2rem;
    h2 {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 1.8rem;
      i {
        font-size: 2rem;
      }
    }
  }
  .sustainability-report-title-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    h1 {
      width: 100%;
      text-align: center;
    }
  }
  .sustainability-report-more-info-text {
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin-bottom: 5.6rem;
  }
  .sustainability-report-overall-section-wrapper {
    flex-direction: column;
    border: unset;
    > div {
      width: 100%;
    }
    .sustainability-report-overall-total-emission {
      border: 0.0625rem solid #EDEBE9;
      margin-bottom: 2.4rem;
      padding: 1rem 0;
      height: unset;
      .sustainability-report-overall-total-emission-label {
        font-size: 1.4rem;
        line-height: 1.68rem;
        margin-bottom: 0.675rem;
      }
      .sustainability-report-overall-total-emission-value {
        line-height: 4rem;
        margin-bottom: 0.521rem;
      }
      .sustainability-report-overall-total-emission-unit {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
    .sustainability-report-overall-chart {
      margin-bottom: 2.4rem;
      border-bottom: none;
    }
    .sustainability-report-overall-scope-wrapper {
      border-top: 0.0625rem solid #EDEBE9;
      border-left: 0.0625rem solid #EDEBE9;
      > div {
        padding: 1rem 0 1.3rem;
        gap: 0.9rem;
        .sustainability-report-scope-item-value {
          font-size: 2.4rem;
        }
        .sustainability-report-scope-item-unit {
          font-size: 1.3rem;
        }
        .sustainability-report-scope-item-label {
          font-size: 1.3rem;
          margin-right: 0.85rem;
        }
        i {
          font-size: 1.8rem;
        }
      }
    }
    .sustainability-report-overall-equivalent-wrapper {
      border: 0.0625rem solid #EDEBE9;
      flex-direction: column;
      gap: 1.8rem;
      padding: 2rem 2rem 0.5rem 2rem;
      .sustainability-report-equivalent-label {
        font-size: 1.6rem;
      }
      .sustainability-report-equivalent-data-list {
        margin-bottom: 1.2rem;
        img {
          width: 1.2rem;
          margin-right: 1.6rem;
        }
        .sustainability-report-equivalent-value {
          font-size: 1.6rem;
          margin-right: 1.4rem;
        }
        .sustainability-report-equivalent-description {
          font-size: 1.4rem;
        }
      }
    }
  }
  .sustainability-report-readmore {
    width: 100%;
    > span {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
  }
  .sustainability-report-emissions-wrapper {
    flex-direction: column;
    gap: 2rem;
    .sustainability-report-emissions-left {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      max-width: unset;
      overflow: auto;
      > div {
        max-width: unset;
        min-width: unset;
        padding: 1.4rem 0.5rem;
        flex: 1 1 0;
        justify-content: flex-start;
        > div:first-child {
          flex-direction: column;
          align-items: center;
        }
        .sustainability-report-emissions-left-value {
          font-size: 2.4rem;
          line-height: 3rem;
          margin: 0;
        }
        .sustainability-report-emissions-left-unit {
          font-size: 1.3rem;
          align-self: unset;
          margin-bottom: 0.6rem;
        }
        .sustainability-report-emissions-left-description {
          font-size: 1rem;
          .ms-TooltipHost {
            align-self: unset;
          }
          i {
            font-size: 2rem;
          }
        }
      }
    }
    .sustainability-report-emissions-right {
      width: 100%;
      .sustainability-report-emissions-item-wrapper {
        justify-content: space-between;
        .sustainability-report-emissions-chart-description {
          > div {
            flex-direction: column;
            span {
              margin: 0;
              align-self: center;
            }
          }
          .sustainability-report-emissions-chart-des-unit {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  .sustainability-report-compare-chart-wrapper {
    flex-flow: column nowrap;
    gap: 2rem;
    .sustainability-report-compare-chart-left {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      max-width: unset;
      overflow: auto;
      > div {
        max-width: unset;
        min-width: unset;
        padding: 1.4rem 0.5rem;
        flex: 1 1 0;
        justify-content: flex-start;
        > div:first-child {
          flex-direction: column;
          align-items: center;
        }
      }
      .sustainability-report-compare-chart-left-value {
        font-size: 2.4rem;
        line-height: 3rem;
        margin: 0;
      }
      .sustainability-report-compare-chart-left-unit {
        font-size: 1.3rem;
        align-self: unset;
        margin-bottom: 0.6rem;
      }
      .sustainability-report-compare-chart-left-label {
        font-size: 1rem;
        text-align: center;
      }
    }
    .sustainability-report-compare-chart-right {
      width: 100%;
    }
  }
  .sustainability-report-portfolio-overview-wrapper {
    width: 100%;
  }
  .sustainability-report-learn-more {
    margin-right: 0;
    h3 {
      font-size: 2.4rem;
      margin: 2.1rem 0 2.4rem;
      line-height: 3.2rem;
    }
    > div {
      padding: 0;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 2.9rem;
    }
  }
  .sustainability-report-epa-legend-wrapper {
    div {
      width: 100% !important;
    }
  }
  .sustainability-report-legend-wrapper {
    margin-top: 3.2rem;
    justify-content: space-between;
    svg {
      flex-shrink: 0 !important;
    }
    .sustainability-report-legend-item-wrapper {
      font-size: 1.4rem;
      width: 45%;
    }
  }
  .sustainability-report-country-table {
    font-size: 1.4rem;
    div {
      font-size: 1.4rem;
    }
    > div {
      grid-template-columns: 25% 27.21% 2.8% 18.36% 3.14% 31.39%;
      overflow: auto;
    }
    .country-table-with-border:after {
      bottom: -2px;
    }
  }
  .sustainability-report-mobile-menu-wrapper {
    .ms-Panel-commands {
      padding-top: 0;
    }
    .ms-Panel-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .sustainability-report-methodology-intro {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}