@media only screen and (max-width: 820px) {
  .tco-report-content-wrapper {
    
    h1 {
      margin: 2rem auto 5.6rem;
      width: 80%;
      text-align: center;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
    .tco-report-intro-wrapper {
      flex-direction: column;
      margin-bottom: 5.6rem;
      > div {
        width: unset;
      }
      .tco-report-intro-left {
        width: 100%;
        padding: 2.6rem 1rem 1rem;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 2.4rem;
        margin-left: 0;
        .tco-report-intro-left-title {
          font-size: 3.2rem;
          line-height: 4rem;
          margin-bottom: 1.2rem;
        }
        .tco-report-intro-left-text {
          font-size: 1.6rem;
          line-height: 2.2rem;
          margin-bottom: 1.6rem;
        }
      }
      .tco-report-intro-right {
        padding: 0;
        span {
          font-size: 1.4rem;
        }
        span:nth-child(2) {
          margin-bottom: 2.4rem;
        }
      }
    }
    .tco-report-intro-paragraph {
      margin-bottom: 5.6rem;
      p {
        font-size: 1.4rem;
        line-height: 2rem;
      }
      p:first-child {
        margin-bottom: 2rem;
      }
    }
    .tco-report-section-wrapper {
      padding: 0 1.6rem;
      margin-bottom: 5.6rem;
      h2 {
        text-align: center;
        font-size: 2rem;
        line-height: 2.4rem;
        width: unset !important;
        margin: 0 0 3.6rem;
      }
      .tco-report-chart-wrapper {
        flex-direction: column;
        > div {
          width: 100%;
        }
        .tco-report-chart {
          margin-bottom: 0;
          order: 1;
        }
        .tco-report-chart-description-wrapper {
          order: 2;
          padding-right: 0;
          h3 {
            font-size: 1.6rem;
            line-height: 2rem;
            margin: 0 0 1.6rem;
            text-align: center;
          }
          > div {
            margin-bottom: 1.6rem;
          }
          .tco-report-chart-description-value {
            font-size: 3.2rem;
            line-height: 3.2rem;
            text-align: center;
            margin-bottom: 1.6rem;
            font-weight: 400;
          }
          .tco-report-chart-description-value-text {
            font-size: 1.6rem;
            line-height: 1.6rem;
            font-weight: 400;
            text-align: center;
            display: block;
            margin-bottom: 1.6rem;
          }
          .read-more-wrapper {
            span {
              font-size: 1.4rem;
              line-height: 2rem;
            }
            margin-bottom: 1rem;
          }
          .tco-report-chart-item-list {
            flex-direction: column;
            div {
              line-height: 2.6rem;
            }
            div:first-child {
              font-size: 1.6rem;
              > div {
                font-size: 1.6rem;
              }
            }
            div:not(:first-child) {
              font-size: 1.4rem;
            }
            &:not(:last-child) {
              // border-bottom: 1px solid #EDEBE9;
              margin-bottom: 0.5rem;
            }
            .tco-report-chart-item-list-marker {
              margin-left: 0;
              margin-right: 0.9rem;
            }
          }
        }
      }
      .tco-report-table-wrapper {
        margin-bottom: 6rem;
        .tco-report-table-row {
          flex-direction: column;
          align-items: flex-start;
          // border-bottom: unset;
          > div {
            width: 100%;
            line-height: 2.6rem;
          }
          > div:first-child {
            font-weight: 400;
            font-size: 1.6rem;
          }
          > div:not(:first-child) {
            justify-content: space-between;
            font-size: 1.4rem;
            > div:first-child {
              font-weight: 400;
            }
          }
          .tco-report-table-total-label {
            padding-left: 0;
          }
          .tco-report-table-value-hour-wrapper {
            width: 40%;
            gap: 1.2rem;
            justify-content: space-between;
            > span {
              width: unset;
            }
          }
        }
      }
    }
    .help-desk-and-security-incidents-wrapper {
      flex-direction: column;
      > div {
        width: 100%;
        > div:first-child {
          margin-bottom: 1.6rem;
        }
      }
      > div:first-child {
        margin-bottom: 4.8rem;
      }
      .help-desk-and-security-incidents-title {
        font-size: 2rem;
        line-height: 2.4rem;
      }
      .help-desk-and-security-incidents-value {
        font-weight: 400;
        font-size: 3.2rem;
        margin-bottom: 1.6rem;
      }
      .help-desk-and-security-incidents-unit {
        margin-bottom: 1.6rem;
        font-size: 2rem;
        line-height: 2.6rem;
      }
      .help-desk-and-security-incidents-des {
        font-weight: 400;
        font-size: 1.6rem;
        span {
          margin-bottom: 1.6rem;
        }
      }
    }
    .tco-report-pdf-link-wrapper {
      padding-top: 2.4rem;
      > div:first-child {
        margin-bottom: 2.4rem;
      }
    }
    .tco-report-warning-wrapper {
      padding: 2rem 2.4rem;
      i {
        font-size: 2rem;
      }
      span {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .costsAndSavingsWrapper{
    width: 60%;
  }

  .intro-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
      width: 90%;
    }
  }
}

.tco-report-content-wrapper {
  position: relative;

  .export_pdf{
    position: absolute;
    top: 2.9rem;
    right: 3rem;

    button {
      background-color: #ffffff;
      border: 1px solid #8A8886;
      cursor: pointer;
      display: flex;
      padding: 0.4rem 1rem;
      width: fit-content;

      i {
        margin-top: 3px;
      }
  
      span {
        font-weight: 600;
        color: #201F1E;
        padding-left: 0.5rem;
      }
    }
  }
  
  
  
}