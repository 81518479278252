.flip-card {
    background-color: transparent;
    width: 14rem;
    height: 11rem;
    perspective: 1000px;
    cursor: pointer;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 1rem;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }

.custom-checkbox .ms-Checkbox-checkbox {
  width: 1rem;
  height: 1rem;
  margin-top: 0.2rem;
}

.custom-checkbox .ms-Checkbox-checkmark {
  font-size: 0.8rem;
}

.custom-checkbox .ms-Checkbox-text {
  font-size: 0.85rem;
}

@media only screen and (max-width: 820px) {
  .mobile-understand-tco-container {
    min-height: max-content;
  }
  .mobile-left-element {
    font-size: 1.4rem;
    padding: 0 1rem 0 0;
  }
  .mobile-item-one-container, .mobile-item-two-container {
    padding-right: 2rem;
  }
  .mobile-item-one-text-container {
    font-size: 1rem;
  }
  .mobile-toggles-container {
    flex-direction: column;
    height: max-content;
  }
  .mobile-toggle-element {
    margin-top: 1rem;
  }
  .mobile-item-two-text-container {
    font-size: 1rem;
  }
  .mobile-icon-element > p{
    font-size: 1rem;
  }
  .mobile-table-container {
    overflow-x: auto;
    width: 98%;
  }
  .mobile-table-container > div:nth-child(1) {
    width: max-content;
  }
  .mobile-table-container > div:nth-child(2) {
    width: max-content;
  }
  .mobile-table-container > div:nth-child(3) {
    width: max-content;
  }
  .mobile-table-container > div:nth-child(4) {
    width: max-content;
  }
  .mobile-table-body {
    width: max-content;
  }
  .mobile-table-total-container > div:nth-child(1) {
    border-right: 1px solid #fff;
  }
  .mobile-table-total-list-item {
    font-size: 0.7rem;
  }
  .mobile-first-element-left {
    padding-right: 1rem;
  }
  .mobile-first-element-left > p:first-child {
    font-size: 1.5rem;
  }
  .mobile-first-element-left > p:last-child {
    font-size: 1rem;
  }
  .mobile-first-element-right {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mobile-first-element-description {
    font-size: 1rem;
  }
  .mobile-first-element-value {
    font-size: 1.5rem;
  }
}

.basic-card {
  border-radius: 8px;
  padding: 1rem;
  width: 19rem;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 18%);
}
