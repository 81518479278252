html {
  font-size: 16px;
}
@media only screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-height: 700px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1080px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-height: 550px) {
  html {
    font-size: 12px;
  }
}
.mobile-display-only {
  display: none;
}
@media only screen and (max-width: 820px) {
  html {
    font-size: 10px;
  }
  .mobile-display-only {
    display: block;
  }
  .mobile-hide {
    display: none;
  }
}

body {
  margin: 0px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  color: #201F1E;
}
.fixed-header {
  position: fixed;
  width: 100vw;
  z-index: 1;
  border-bottom: 2px solid #E7E7E7;
  top: 0;
}

footer {
  .c-uhff {
    margin-top: 0;
  }
}
a {
  color: #0078D4;
}
.ms-Fabric.ms-Layer-content {
  color: #201F1E;
}
.ms-TextField-fieldGroup, .ms-Dropdown-title, .ms-ComboBox::after {
  border: 1px solid #D6D6D6;
  margin: 0 1px;
}
.ms-TextField-fieldGroup .ms-TextField-prefix {
  background: none;
}
.ms-TextField-wrapper .ms-Label,
.ms-Dropdown-container .ms-Label {
  font-weight: 400;
}
.ms-Dialog-main {
  border-radius: 1.25rem;
  min-height: unset;
}
.ms-Button--compound .ms-Button-textContainer .ms-Button-label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.ms-Button--compound .ms-Button-textContainer .ms-Button-description {
  font-size: 0.875rem;
}
.ms-TextField-wrapper input.ms-TextField-field,
.ms-Dropdown-title,
.ms-Dropdown-optionText,
.ms-ComboBox,
.ms-ComboBox-optionText {
  font-size: 1rem;
  color: #191919;
}
.ms-Dropdown-items {
  button:hover {
    color: unset;
  }
}
.ms-ComboBox-optionsContainer {
  max-height: 40vh;
  .is-checked {
    background-color: rgb(243, 242, 241);
  }
}
@media only screen and (max-width: 820px) {
  .ms-TextField-wrapper input.ms-TextField-field,
  .ms-Dropdown-title,
  .ms-Dropdown-optionText,
  .ms-ComboBox-optionText {
    font-size: 1.6rem;
  }
  .ms-Dropdown-items {
    button {
      height: 4rem;
    }
  }
  .ms-ComboBox-optionsContainer {
    height: 20vh;
  }

}

@media only screen and (min-width: 821px){
  
  .global-head-cont {
    padding: 0 3.5rem !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.m0 {margin: 0;}

.nxt-line-title{
  display: block;
  font-size: 1.25rem;
  font-weight: 300;
  text-transform: uppercase;
}

.pdf{
  font-family:  -apple-system, BlinkacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  color: #191919;
  letter-spacing: 0.01px;
  margin:0;
  padding: 0;

  p{
    margin: 0;
  }

  .d-flex{
    display: flex;
  }

  .vh-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content_section {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    padding: 25px 30px;

    .flex_col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .mb-30 {
      margin-bottom: 30px;
    }

    

    .report_title {
      font-size: 40px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 15px;
    }

    .title_1 {
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
    }

    .title_right {
      color: #3C7DCC;
      font-size: 10px;
      font-weight: 400;
      line-height: 11px;
      text-align: right;
      margin-bottom: 15px;
    }

    .p-table {
      justify-content: space-between;

      >div{
        width: 47.5%;
      }
    }

    .footer-text{
      border: 0 !important; 
      margin: 5px auto;
      text-align: center;
      width: 70%;
    }

    .pricing-table{
      background-color: #F0FAFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px;
      width: calc(100% - 10px);

      .highlight {
        border-bottom: 1px solid #6BA6D2;
        color: #005B9B;
        font-size: 14px;
        font-weight: 600;
        padding: 3px 0 4px;
        margin-bottom: 10px;
      }

      ul{
        list-style: none;
        padding: 0;
        margin: 0;

        li{
          margin: 7.5px 0;
          

          &.table-total {
            align-items: center;
            border-top: 1px solid #6BA6D2;
            color: #005B9B;
            margin-top: 0;
            padding-top: 10px;
                          
            .t-sub-title{
              color: #3C7DCC;
              font-size: 8px;  
            }
  
            .t-amount {
              font-size: 10.5px;
            }

            .t-title{              
              font-size: 10px
            }
          }

          >div{
            padding-left: 5px;
          }

          .t-title{
            
            font-size: 9px;
            line-height: 10px;
            font-weight: 600;
          }

          .t-sub-title{
            color: #666;
            font-size: 7px;
            line-height: 9px;
            font-weight: 400;

          }

          .t-amount {
            font-size: 9px;
            font-weight: 400;
            line-height: 10px;
            min-width: 55px;
            text-align: right;
          }

          
          
        }
      }
    }
  }

  .chart_sec {
    display: flex;

    img {
      width: 250px;
    }

    .price_text {
      font-size:30px; 
      line-height: 30px;
      font-weight:bold;
      margin: 10px 0;
    }

    .title_text {
      font-size:13px;
      line-height: 15px;
      margin:0;
      margin-bottom: 15px;
    }

    .desc_text {
      font-size:10px; 
      margin:0;
      margin-bottom: 10px;
      padding: 1px;
    }
  }

  a{
    border-bottom: 1px solid #0078D4;
    padding-bottom: 1px;
  }

  .main_title{
    font-size: 28px !important;
    line-height: 28px !important;
    margin-bottom: 5px;
  }

  .main_title_sub{
    font-size: 14px;
    line-height: 28px !important;
    margin-bottom: 25px;
  }

  .sub_title {
    border-bottom: 2px solid #F6F6F6;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
    padding: 5px 0;
  }
  
  .main_sec {
    display: flex;

    .content_sec {
      p{
        font-size: 10px;
        line-height: 13px;
        margin-bottom: 20px;
      } 

      .title_sec {
        display: flex;
        align-items: center;
        padding: 0 20px;

        .main_title {
          margin-bottom: 50px;
        }
      }      

      .formated_content_sec {
        background-color: rgba(242, 242, 242, 0.7);
        display: flex;
        align-items: center;
        padding: 0 20px;

        .formated_value{
          color: #0078D4;
          font-size: 32px;
          margin-bottom: 15px;
          margin-top: 0;
        }

        .formated_value_desc{
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 35px;
        }       

      }      
    }    
  }

  .marker{
    display: inline-block;
    margin: 0 5px !important;
    border-radius: 1.5rem;
    width: 12px  !important;
    height: 12px  !important;
  }

  th{
    text-align: left;
  }

  th, td{
    margin: 0 2px;
    padding: 8px 0px;
  }

  .section_1 {
    padding: 15px 20px 0;
    width:100%;
  }

  .section_2 {
    padding: 0 20px 15px;
    width:100%;

    .conclusion {
      font-size: 10px;
    }
  }

  .one_pager{
    padding-top: 35px;

    .main_title{
      font-size: 22px !important;
      line-height: 22px !important;
      margin: 5px 0;
      margin-top: 5px;
    }

    .sub_title{
      border-bottom: 1px solid #6BA6D2;
      color: #005B9B;
      font-size: 13px;
      font-weight: 600;
      line-height: 16px;
    }

    .main_title_sub{
      color: #005B9B;
      font-size: 13px;
      line-height: 14px !important;
    }

    .methodolgy-sec {
      p {
        font-size: 8px;
        line-height: 12px;
        margin-bottom: 2.5px;

        &.title{
          color: #005B9B;
          font-size: 9px;
          font-weight: bold;
          text-align: center;
        }
      }

      .bubble-sec {
        
        font-size: 8px;
        text-align: right;

        span{
          background-color: #F0FAFF;
          border-radius: 15px;
          display: inline-block;
          padding: 2.5px 10px;
        }
      }
      
    }

    .methodolgy-sec-minimized {
      p {
        font-size: 7px;
        line-height: 10px;
        margin-bottom: 2.5px;

        &.title{
          color: #005B9B;
          font-size: 9px;
          font-weight: bold;
          text-align: center;
        }
      }

      .bubble-sec {
        
        font-size: 8px;
        text-align: right;

        span{
          background-color: #F0FAFF;
          border-radius: 15px;
          display: inline-block;
          padding: 2.5px 10px;
        }
      }
      
    }

    .sus-savings {
      // background-color: #F0FAFF;
      background-color: #c4ffc3;
      margin-top: 5px;
      padding: 5px 10px;
      // text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      >div:first-child {
        // background-color: red;
        width: 7%;
        img {
          width: 24px;
          object-fit: contain;
        }
      }
      >div:last-child {
        width: 93%;
      }
    }
    
  }
  
 
} 
