@media only screen and (max-width: 820px) {
  .two-part-layout-wrapper {
    background: #fff;
    min-height: unset;
    .two-part-layout-left {
      width: 100%;
      padding-bottom: 0;
      max-width: unset;
    }
    .two-part-layout-right {
      width: 100%;
      height: unset;
      margin-left: 0;
    }
    .two-part-layout-bottom {
      width: 100%;
      position: unset;
      text-align: center;
      padding-bottom: 3.3rem;
    }
    .two-part-layout-back-to-top {
      width: 100%;
    }
  }
}