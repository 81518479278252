.tco-landing-wrapper {
  > div {
    max-width: unset;
  }
  .two-part-layout-left {
    max-width: unset;
  }
  .two-part-layout-bottom {
    max-width: unset;
    background-color: unset;
    display: flex;
    justify-content: flex-end;
  }
  .selection-box-list-wrapper {
    .maximum-message {
      display: none;
    }
    .selection-box-title-wrapper {
      margin-bottom: 1.375rem;
      h2 {
        font-size: 1.125rem;
      }
    }
    .selection-box-content-wrapper {
      grid-template-columns: 1fr;
      max-height: calc(100vh - 520px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }
       
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }
       
      &::-webkit-scrollbar-thumb {
        background-color: rgba(117, 117, 122, 0.3);
        outline: 1px solid rgb(117, 117, 122, 0.5);
        border-radius: 10px;
      }
    }
  }
  .selection-box-add-box {
    min-height: calc(6.4062rem + 80px);
  }

 
}
.imageContainer{
  display: flex;
  justify-items: center;
  padding: 25px 0;
  > div {
    flex: 1;    
    text-align: center;
  }

  span{
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  
  img{
    width: 175px;
    padding-bottom: 5px;
  }
}
@media only screen and (max-width: 820px) {
  .imageContainer{
    img{
      width: 100px;
    }
  }
  // .tco-landing-wrapper {
    .tco-landing-left-part {
      height: unset;
      padding: 0;
      img {
        max-height: 18.9rem;
        object-position: 0 0;
      }
    }
    .tco-landing-right-part {
      padding: 3.2rem 1.6rem;
      h1 {
        text-align: center;
        font-size: 2.6rem;
        line-height: 3.2rem;
        margin: 0 0 2.4rem 0;
      }
      .tco-landing-right-part-intro-text {
        text-align: center;
        span {
          font-size: 1.6rem;
          line-height: 2.0rem;
          margin-bottom: 4rem;
        }
      }
      .ms-Dropdown-label {
        font-size: 1.8rem;
      }
      .selection-box-list-wrapper {
        margin-top: 4rem;
      }
      .selection-box-title-wrapper {
        h2 {
          font-size: 1.6rem;
        }
      }
      .selection-box-content-wrapper {
        .selection-box-quantity-input {
          width: 100%;
          margin-right: 0;
        }
        .selection-box-subscription-input {
          width: 100%;
          // margin-bottom: 0;
        }
      }
      .selection-box-add-box {
        min-height: calc(6.4062rem + 128px);
      }
    }
    .two-part-layout-bottom {
      > div {
        width: 100%;
        border-top: 1px dashed #D2D0CE;
        margin: 0 1.6rem;
      }
      button {
        margin: 5.6rem 0;
        font-size: 1.4rem;
        width: 13.6rem;
        height: 3.9rem;
      }
    }
  // }
  .bottom-part-container {
    padding: 0 1.6rem 1.6rem;
    .disclaimer-wrapper {
      flex-direction: column-reverse;
    }
    .disclaimer-wrapper > div {
      width: 100%;
      font-size: 1.6rem;
      line-height: 2.24rem;
      padding: 0;
      text-align: left;
      margin-bottom: 2rem;
      color: #201F1E;
    }
    .disclaimer-wrapper > button {
      margin: 0 auto 2rem;
      width: 100%;
      max-width: none;
    }
  }

  .tco-input-mobile-wrapper > div:last-child {
    width: 100%;
  }
}

@media only screen and (max-width: 481px) {
.imageContainer {
  display: none;
}
}