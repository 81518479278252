@media only screen and (max-width: 820px) {
    .sus-mobile-container {
        height: 40vh;
    }
    .sus-mobile-first-element-wrapper {
        flex: 50%;
        justify-content: center;
    }
    .sus-mobile-second-element-wrapper {
        flex: 50%;
        background-image: none;
        justify-content: center;
    }
}

@media only screen and (min-width: 820px) and (max-width: 1080px) {
    .sus-mobile-first-element-wrapper {
        flex: 50%;
        justify-content: center;
    }
    .sus-mobile-second-element-wrapper {
        flex: 50%;
        background-image: none;
        justify-content: center;
    }
}