.sus-flip-card {
    background-color: transparent;
    width: 16rem;
    height: 13rem;
    perspective: 1000px;
    cursor: pointer;
  }
  
  .sus-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 1rem;
  }
  
  .sus-flip-card:hover .sus-flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .sus-flip-card-front, .sus-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .sus-flip-card-back {
    transform: rotateY(180deg);
  }

@media only screen and (max-width: 820px) {
  .mobile-understand-sus-container {
    min-height: max-content;
  }
  .sus-mobile-left-element {
    font-size: 1.4rem;
    padding: 0 1rem 0 0;
  }
  .sus-mobile-item-one-container, .sus-mobile-item-two-container {
    padding-right: 2rem;
  }
  .sus-mobile-item-one-text-container {
    font-size: 1rem;
  }
  .sus-mobile-toggles-container {
    flex-direction: column;
    height: max-content;
  }
  .sus-mobile-toggle-element {
    margin-top: 1rem;
  }
}
