.mobile-tooltip-accordion-wrapper {
  margin-top: 1.5rem !important;
  > div:not(:first-child) {
    margin-bottom: 0.4rem;
  }
  .mobile-tooltip-accordion-title {
    justify-content: flex-start;
    gap: 2.2rem;
    span {
      order: 2;
      color: #201F1E;
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 400;
    }
    i {
      order: 1;
      color: #605E5C;
      font-size: 1.6rem;
    }
  }
  ul {
    padding-left: 1.5rem;
  }
}