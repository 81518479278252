@media only screen and (max-width: 820px) {
  .yes-or-no-question-wrapper {
    margin-bottom: 4rem;
    .yes-or-no-question {
      font-size: 1.8rem;
      line-height: 2.7rem;
    }
    .yes-or-no-question-description {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    button {
      height: 6.4rem;
      .ms-Button-flexContainer .ms-Button-textContainer .ms-Button-label {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}