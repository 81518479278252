.assumption-input-wrapper {
  .ms-TextField {
    margin: 0;
    .ms-TextField-fieldGroup {
      border: 1px solid #323130;
      background-color: #FFFFFF;
      .ms-TextField-prefix {
        padding-top: 0.1rem;
        padding-right: 0.1rem;
        color: #201F1E;
        font-size: 0.875rem;
        line-height: 0.875rem;
      }
      input {
        padding-left: 0;
        color: #201F1E;
        font-size: 0.875rem;
        &:disabled {
          background-color: #FAF9F8;
        }
      }
    }
  }
  &.disabled {
    .ms-TextField-fieldGroup {
      border-color: #A19F9D;
      .ms-TextField-prefix, input {
        color: #A19F9D;
      }
    }
  }
  &.only-disable-input-box {
    .ms-TextField-fieldGroup {
      border-color: #A19F9D;
      .ms-TextField-prefix, input {
        // color: #A19F9D;
        background-color: #FAF9F8;
        color: #201F1E;
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .assumption-input-item-wrapper {
    margin-bottom: 2.2rem;
    align-items: center;
    .assumption-input-label {
      font-size: 1.4rem;
      line-height: 2rem;
      width: 57%;
      align-items: center;
      justify-content: space-between;
    }
    .assumption-input-wrapper {
      width: 35%;
    }
    .ms-TextField .ms-TextField-fieldGroup {
      background-color: #ffffff;
      .ms-TextField-prefix {
        font-size: 1.4rem;
      }
      input {
        font-size: 1.4rem;
      }
    }
    .ms-Checkbox {
      padding-right: 0.4rem;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .assumption-input-label-reduced {
    width: 60%;
    justify-content: space-between;
  }
  .assumption-input-wrapper-reduced {
    width: 15%;
    max-width: 5.84rem;
    min-width: 5.84rem;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1480px) {
  .assumption-input-label-reduced {
    width: 65%;
    justify-content: space-between;
  }

  .assumption-input-wrapper-reduced {
    width: 15%;
    max-width: 5.84rem;
    min-width: 5.84rem;
  }
}

@media only screen and (min-width: 1480px) and (max-width: 1680px) {
  .assumption-input-label-reduced {
    width: 65%;
    justify-content: space-between;
  }

  .assumption-input-wrapper-reduced {
    width: 15%;
    max-width: 7.84rem;
    min-width: 7.84rem;
  }
}

@media only screen and (min-width: 1680px) and (max-width: 1780px) {
  .assumption-input-label-reduced {
    width: 65%;
    justify-content: space-between;
  }

  .assumption-input-wrapper-reduced {
    width: 15%;
    max-width: 7.84rem;
    min-width: 7.84rem;
  }
}

@media only screen and (min-width: 1780px) {
  .assumption-input-label-reduced {
    width: 65%;
  }

  .assumption-input-wrapper-reduced {
    width: 15%;
    max-width: 7.84rem;
    min-width: 7.84rem;
  }
}