@media only screen and (max-width: 820px) {
  .selection-box-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    div, span, h3 {
      font-size: 1.6rem;
    }
    .selection-box-title-icon {
      width: 2rem;
      height: 2rem;
    }
    .selection-box-delete-button {
      font-size: 1.8rem;
    }
    .maximum-message {
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-bottom: 4rem;
    }
  }
}