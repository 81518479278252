.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Segoe UI';
}

.fixed-header {
    position: fixed;
    width: 100vw;
    z-index: 1;
    border-bottom: 2px solid #E7E7E7;
    top: 0;
  }