.overflow-scroll {
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
      background-color: rgba(117, 117, 122, 0.3);
      outline: 1px solid rgb(117, 117, 122, 0.5);
      border-radius: 10px;
  }
}

@media only screen and (max-width: 820px) {
  .sustainability-calculator-wrapper {
    .two-part-layout-wrapper {
      .two-part-layout-right {
        order: 1;
        > div {
          padding-top: 0;
        }
        .mobile-page-title {
          text-align: center;
          font-size: 2.6rem;
          line-height: 3.2rem;
          font-weight: 400;
          margin: 0 0 4.8rem;
          text-align: center;
        }
        .selection-box-title-wrapper {
          margin-bottom: 4rem;
        }
      }
      .two-part-layout-left {
        order: 2;
        background-color: #ffffff;
        h1 {
          margin: 0;
          padding: 2.4rem 0;
          background-color: #ffffff;
        }
      }
      .two-part-layout-bottom {
        display: none;
      }
    }
    .two-part-layout-back-to-top {
      order: 3;
    }
    .sustainability-left-part-intro-text {
      font-size: 1.4rem;
      line-height: 2.1rem;
      margin-bottom: 4rem;
      i {
        font-size: 1.55rem;
      }
    }
  }
  .sustainability-left-content-wrapper {
    padding: 0;
    height: unset;
    .mobile-view-report-wrapper {
      button {
        width: 13.6rem;
        height: 3.8rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin: 4rem 0 3.2rem;
      }
    }
    .sustainability-left-mobile-accordion {
      margin: 0;
      padding: 2.4rem 1rem;
      border: 1px solid #E1DFDD;
      .sustainability-left-mobile-button-wrapper {
        button {
          margin: 4.8rem 0 2.4rem;
        }
      }
    }
    .sustainability-left-mobile-accordion-header-wrapper {
      justify-content: center;
    }
    .sustainability-left-key-value-wrapper {
      margin: 0 2.7rem 2.4rem;
      padding: 1rem 0 0;
      .sustainability-left-key {
        font-size: 1.4rem;
        line-height: 1.68rem;
      }
      .sustainability-left-value {
        font-size: 4rem;
        line-height: 4rem;
      }
    }
    .sustainability-left-scope-wrapper {
      position: relative;
      border: unset;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4.8rem;
      padding: 0 1.7rem;
      width: 90vw;
      div, span {
        font-size: 1.6rem;
      }
    }
  }
  .selection-box-content-wrapper {
    grid-template-columns: 1fr;
  }
  .sus-first-time-modal-wrapper {
    width: unset;
    .sus-first-time-modal-title {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
    .sus-first-time-modal-content {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-bottom: 1.8rem;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .selection-box-content-wrapper {
    grid-template-columns: 1fr;
  }
}