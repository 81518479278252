.ms-Pivot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 13rem;
  .is-selected {
    &::before {
      background: none;
    }
  }
  .ms-Pivot-icon{
    color: #0078D4;
  }
}

// .chatbot-toggle-container .ms-Pivot{
//   width: max-content;
//   margin-right: 1rem;
// }

.chatbot-toggle-container{
  margin-right: 2.5rem;
}

.ms-Pivot button:hover {
  background: none;
}

// .ms-Pivot > button:nth-child(2) {
//   margin-right: 0;
//   padding-right: 0;
// }

.ms-Pivot > button:nth-child(1) {
  flex-grow: 1;
  width: 57%;
  padding: 0;
  margin: 0;
  text-align: left;
}

.ms-Pivot > button:nth-child(2) {
  flex-grow: 1;
  width: 43%;
  padding: 0;
  margin: 0;
  text-align: left;

}

  .tco-assumptions-right-part {
    background-color: rgba(242, 242, 242, 0.7);
    overflow: auto;
    .ms-Pivot {
      margin: 1.44rem 1rem 0 4rem;
      display: flex;
      justify-content: flex-end;
      .is-selected {
        &::before {
          background: none;
        }
      }
      .ms-Pivot-icon{
        color: #0078D4;
      }
    }

    .tco-assumptions-advanced-input-section {
      margin-bottom: 1.875rem;
    }
    
  }
  .two-part-layout-bottom {
    //background-color: unset;
    display: flex;
    justify-content: flex-end;
  }
  .ms-Toggle {
    margin-bottom: 1.4375rem;
    .ms-Toggle-label {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .tco-assumptions-left-part {

    max-height: calc(100vh - 260px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }
      
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }
      
    &::-webkit-scrollbar-thumb {
      background-color: rgba(117, 117, 122, 0.3);
      outline: 1px solid rgb(117, 117, 122, 0.5);
      border-radius: 10px;
    }

    .tco-assumption-right-title-accordion {
      margin-bottom: 1rem;
      i {
        color: #201F1E;
      }
    }
  }

  .per-device-wrapper {
    .ms-Pivot {
      margin: 0;
    }
  }

.export_pdf{
  margin-right: 1rem;
}

@media only screen and (max-width: 820px) {
  .export { 
    position: static;
    justify-content: center;
  }

  .layout-container {
    flex-direction: column;
  }

  .layout-container > div:first-child, .layout-container > div:last-child {
    width: 100%;
  }

  .common-container {
    flex-direction: column;
    margin: 0 2rem 2rem;
    padding: 2rem 0 0;
    .mobile-title {
      text-align: center;
      margin-bottom: 1rem;
    }
    .tco-learn-more-text {
      text-align: center;
    }
    .mobile-button-wrapper {
      margin-bottom: 1rem;
      display: block !important;
    }

    div:nth-child(3) {
      justify-content: center;
    }

    // .ms-Pivot {
    //   justify-content: center;
    // }
  }

    .tco-assumptions-right-part {
      background-color: #ffffff;
      box-sizing: border-box;
      margin: 0;
      padding: 1rem 2rem 2rem;
      .per-device-wrapper{
        flex-direction: column;
        padding: 0;
        .common-right-header {
          font-size: 2rem;
          margin-bottom: 1rem;
        }
      }
      .ms-Pivot {
        display: flex;
        justify-content: center;
        margin-left: 1rem;
        margin-right: 1rem;
        width: 20rem;
      }
      .ms-Pivot button:hover {
        background: none;
      }
      h1 {
        font-size: 2.8rem;
        font-weight: 400;
        line-height: 3.2rem;
        margin: 0 0.9rem 2rem;
        text-align: center;
        margin-top: 4rem;
      }
      .mobile-button-wrapper {
        margin-bottom: 4.2rem;
        > div {
          width: 100%;
          background-color: unset;
          > div {
            padding-left: 0;
            justify-content: center;
          }
        }
        button {
          font-size: 1.4rem;
          width: 13.6rem;
          height: 3.9rem;
          padding: 0;
        }
        .ms-Button-label {
          margin: 0;
        }
      }
      .tco-learn-more-text {
        text-align: center;
        margin: 0 1.6rem;
      }
      .accordion-wrapper {
        margin: 0;
        margin-bottom: 1rem;
      }
      .tco-standard-view-mobile-accordion-wrapper {
        margin-bottom: 0;
      }
      .tco-standard-view-mobile-accordion-header-wrapper {
        justify-content: flex-start;
      }
      .tco-standard-view-content-wrapper {
        margin: 0;
        padding: 1rem 0.8rem;
        border: none;
        .tco-standard-view-first-section {
          margin-bottom: 3rem;
        }
        .tco-standard-view-top-section-wrapper {
          margin: 0 0 4rem;
          justify-content: flex-start;
          gap: 0;
          .tco-standard-view-top-section-label {
            width: 10rem;
            font-size: 1.4rem;
            line-height: 1.6rem;
            margin-right: 2rem;
          }
          .tco-standard-view-top-section-value {
            font-size: 2.4rem;
            line-height: 3.6rem;
          }
        }
        .tco-standard-view-section-title {
          font-size: 2rem;
          line-height: 2rem;
          margin-bottom: 2.4rem;
        }
        .tco-standard-view-summary-title {
          font-size: 2rem;
          line-height: 2rem;
          margin-bottom: 1rem;
        }
        .tco-standard-view-xaxis-label-wrapper {
          > div {
            font-size: 1rem;
            line-height: 1.2rem;
            gap: 0.5rem;
          }
        }
        .tco-mobile-chart-wrapper {
          margin-bottom: 3rem;
          .tco-chart-title-wrapper {
            font-size: 1.4rem;
          }
          .mobile-chart-value {
            margin: 0 0 0.6rem;
          }
        }
        .summary-wrapper {
          .summary-lines-wrapper {
            align-items: center;
            margin-bottom: 1rem;
          }
          .summary-lines-wrapper > div:nth-child(1) {
            width: 30%;
          }
          .summary-lines-wrapper > div:nth-child(2) {
            width: 40%;
            margin-bottom: 0;
          }
          .summary-lines-wrapper > div:nth-child(3) {
            width: 30%;
          }
          .summary-container {
            flex-wrap: wrap;
          }
          .summary-container > div {
            width: 45%;
            padding-bottom: 1rem;
          }
        }
      }
      .tco-assumptions-advanced-wrapper {
        // height: unset;
        flex-direction: column-reverse;
        padding-top: 0;
        margin-top: 0;
        border: none;
        .tco-assumptions-advanced-input-wrapper {
          flex-direction: column;
          padding: 0 1.6rem;
          border: 1px solid rgb(225, 223, 221);
          border-top: none;
          margin-right: 0;
          background-color: #F2F2F2B2;

          > div:first-child {
            margin-top: 1.5rem;
          }
          .advanced-checkbox-wrapper {
            width: 100%;
            padding: 1rem 1rem 0rem;
            .total-field-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 1.6rem;
              > label {
                font-size: 1.6rem;
              }
            }
          }
          .tco-assumptions-advanced-input-section-title {
            font-size: 2rem;
            line-height: 2rem;
            font-weight: 600;
            margin-bottom: 2rem;
            flex-direction: column;
            > div:first-child {
              align-self: flex-start;
            }
            > div:last-child {
              align-self: flex-end;
            }
          }
          .ms-Toggle {
            margin-bottom: 2.4rem;
            .ms-Toggle-label {
              font-size: 1.4rem;
              line-height: 2rem;
              margin-left: 0.8rem;
            }
          }
        }
        .tco-assumptions-advanced-total-value-wrapper {
          border: unset;
          margin: 0;
          padding: 0 1.6rem 3.5rem;
          .tco-assumptions-advanced-total-value-label {
            font-size: 1.6rem;
            line-height: 2.1rem;
            margin-bottom: 0.4rem;
          }
          .tco-assumptions-advanced-total-value {
            font-size: 3.2rem;
            line-height: 3.8rem;
          }
          .summary-container {
            flex-wrap: wrap;
          }
          .summary-container > div {
            width: 45%;
            padding-bottom: 1rem;
          }
        }
        .tco-assumptions-advanced-summary-button {
          margin: 0 0 3.7rem 1.6rem;
          height: 3.6rem;
          width: 12rem;
          background-color: #ffffff;
          div {
            font-size: 1.6rem;
            justify-content: center;
          }
          div span, i{
            font-size: 2rem;
          }
        }
        .total-summary-wrapper {
          margin-top: 2rem;
          .summary-button-wrapper {
            padding: 0;
            align-items: center;
            margin-bottom: 1rem;
            .tco-assumptions-advanced-summary-button {
              margin: 0;
            }
          }

          .summary-button-wrapper > div:nth-child(1) {
            width: 30%;
          }
          .summary-button-wrapper > div:nth-child(2) {
            width: 40%;
            margin-bottom: 0;
            justify-content: center;
          }
          .summary-button-wrapper > div:nth-child(3) {
            width: 30%;
          }





        }
      }
      .assumptionsEditor {
        margin-top: 2rem;
      }
      .accordion-header-exception > div:first-child {
        justify-content: flex-start;
      }
    }

      .tco-assumption-right-title-accordion {
        margin-bottom: 0.8rem;
        .accordion-header {
          margin-bottom: 1.6rem;
        }
        > span {
          font-size: 1.6rem;
        }
        .tco-assumptions-right-part-title {
          gap: 1rem;
        }
        .tco-assumptions-right-part-title-icon {
          font-size: 2.4rem !important;
        }
      }
      .tco-assumptions-right-part-input-accordion-text {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 4rem;
      }
      .tco-assumptions-right-part-info-text {
        font-size: 1.6rem;
        line-height: 2.24rem;
        margin-bottom: 3.9rem;
      }
      .accordion-wrapper {
        .accordion-header {
          span {
            font-size: 1.6rem;
            line-height: 2rem;
          }
          i {
            font-size: 1.2rem;
          }
        }
        .ms-Label {
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
        .yes-or-no-question-wrapper {
          // margin-bottom: 4rem;
          .yes-or-no-question {
            font-size: 1.4rem;
            line-height: 2.2rem;
          }
          // .yes-or-no-question-description {
          //   font-size: 1.6rem;
          //   line-height: 2.4rem;
          // }
          button {
            height: 4rem;
            .ms-Button-flexContainer .ms-Button-textContainer .ms-Button-label {
              font-size: 1.6rem;
              line-height: 2rem;
            }
          }
        }
      }
    // }

    .two-part-layout-bottom {
      display: none;
      // & + div {
      //   display: none;
      // }
    }
  .tco-report-modal-wrapper {
    width: calc(100vw - 32px);
    height: calc(100vh - 32px);
  }
  .tco-assumptions-advanced-summary-label {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 0.6rem;
  }
  .tco-assumptions-advanced-summary-value {
    font-size: 2.4rem;
    line-height: 2.4rem;
    margin-bottom: 1.8rem;
  }
  .tco-assumption-first-time-modal-wrapper {
    width: unset;
    .tco-assumption-first-time-modal-title {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
    .tco-assumption-first-time-modal-content {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-bottom: 1.8rem;
    }
  }

  .assumptions-header-wrapper {
    top: 2rem;
    left: 1rem;
  }



  .input-styles-container{
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  .input-styles-assumption-container {
    margin-right: 0;
  }

  .input-styles-container label{
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  .input-styles-container .ms-Button .ms-Button-flexContainer .ms-Button-textContainer .ms-Button-label {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .input-styles-container .custom-option-list li > div:nth-child(2){
    font-size: 1.4rem;
  }

  .input-styles-container .custom-option-list li{
    box-sizing: border-box;
    >div:nth-child(1){
      width: 4%;
      padding: 1rem 1rem 1rem 0;
    }

    >div:nth-child(2){
      width: 36%;
      padding: 1rem 0.5rem;
    }

    >div:nth-child(3), >div:nth-child(4){
      width: 30%;
      padding: 1rem 0.5rem;

      display: flex;
      align-items: center;
      justify-content: center;
        > span{
          width: 20%;
          margin-bottom: 0;
          top: auto;
          font-size: 1rem;
          // padding: 0;
        }
        >input{
          width: 70%;
          font-size: 1.2rem;
          padding-top: 5px;
          padding-bottom: 5px;
        }
    }
  }

  .input-styles-container .custom-option-list .head {
    box-sizing: border-box;
    > div:first-child{
      width: 4%;
      padding: 1rem 1rem 1rem 0;
    }

    > div:nth-child(2){
      width: 36%;
      padding: 1rem 0.5rem 1rem 0;
    }

    > div{
      width: 30%;
      padding: 1rem 0.5rem;
    }

  }

.mobile-margin-top {
  margin-top: 5rem;
}

.mobile-reults-editor-label {
  font-size: 1.4rem;
}

.mobile-results-editor-label-wrapper > div:first-child {
  font-size: 1.4rem;
}

.input-sec .ms-Pivot {
  width: 20rem;
}


}

.tco-assumptions-advanced-input-wrapper {
  &::-webkit-scrollbar {
    width: 5px;
  }
    
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
    
  &::-webkit-scrollbar-thumb {
    background-color: rgba(117, 117, 122, 0.3);
    outline: 1px solid rgb(117, 117, 122, 0.5);
    border-radius: 10px;
  }
}

.layout-container {

  .left-top {
    .assumptionsEditor{
      margin-bottom: 20px;
      max-height: 53vh;//calc(100vh - 240px);
      overflow-y: auto;      
      padding-right: 10px;

        &::-webkit-scrollbar {
          width: 5px;
        }
          
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
        }
          
        &::-webkit-scrollbar-thumb {
          background-color: rgba(117, 117, 122, 0.3);
          outline: 1px solid rgb(117, 117, 122, 0.5);
          border-radius: 10px;
        }
    }
    .tco-assumption-right-title-accordion {
      margin-bottom: 1rem;
      i {
        color: #201F1E;
      }
    }
  }
}

.d-block{
  display: block;
}



.update-btn {
  margin-top: 10px;
  text-align: center;

  // button{
  //   background-color: #fff;
  //   border: 1px solid #D6D6D6;
  //   border-radius: 2px;   
  //   font-weight: 600;
  //   font-size: 16px;
  //   line-height: 21px;
  //   padding: 10px;
  //   text-align: center;
  // }
}

.mb-15{
  margin-bottom: 15px;
}

.custom-option-list {
  list-style: none;
  padding: 0;

  li{
    display: flex;
    align-items: center;

    
    >div{
      padding: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      input{
        font-size: 1rem;
        padding: 7px 10px 7px 35px;
        // text-align: center;
        width: calc(100% - 45px);
      }
    }

    >div:not(:first-child){
      width: 40%;
    }

    >div:first-child {
      width: 4%;
      padding-left: 0;
      .ms-Checkbox-label{
        margin-bottom: 0;
      }
    }

    >div:nth-child(2) {
      width: 16%;
      font-size: 1rem;
    }

    

    &.head > div{
      font-weight: 600 !important;      
      text-align: center;
    }

    .curr-field{
      position: relative;
    
      span {
        position: absolute;
        top: 17px;
        left: 15px;
        font-size: 14px;
        font-weight: 400;
        width: 30px;
        display: inline-block;
        text-align: center;
      }
    }
  }
}

.input-sec{
  
  .export-excel{
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #8A8886;
    cursor: pointer;
    display: flex;
    height: fit-content;
    margin-right: 15px;
    padding: 0.4rem 1rem;
    width: fit-content;

    i {
      margin-top: 3px;
    }

    span {
      font-weight: 600;
      color: #201F1E;
      padding-left: 0.5rem;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .summary-lines-wrapper > div:nth-child(2) {
    width: 26%;
  }

  .summary-lines-wrapper-reduced > div:nth-child(2) {
    width: 40%;
  }

  .summary-container {
    flex-wrap: wrap;
  }

  .summary-container-reduced {
    flex-wrap: wrap;
  }

  .summary-item {
    flex: 0 0 calc(50% - 10px);
    // margin: 5px;
    h2 {
      font-size: 1.74rem;
    };
    p {
      font-size: 0.88rem;
    }
    .info-icon-updated {
      font-size: 0.85rem;
    }
  }

  .summary-item-reduced {
    flex: 0 0 calc(50% - 10px);
    // margin: 5px;
    h2 {
      font-size: 1.74rem;
    };
    p {
      font-size: 0.88rem;
    }
    .info-icon-updated {
      font-size: 0.85rem;
    }
  }

}

@media only screen and (min-width: 1080px) and (max-width: 1280px) {
  .summary-lines-wrapper > div:nth-child(2) {
    width: 24%;
  }

  .summary-lines-wrapper-reduced > div:nth-child(2) {
    width: 34%;
  }

  .summary-container-reduced {
    flex-wrap: wrap;
  }

  .summary-item {
    h2 {
      font-size: 1.54rem;
    };
    p {
      font-size: 0.78rem;
    }
    .info-icon-updated {
      font-size: 0.75rem;
    }
  }

  .summary-item-reduced {
    flex: 0 0 calc(50% - 10px);
    h2 {
      font-size: 1.74rem;
    };
    p {
      font-size: 0.88rem;
    }
    .info-icon-updated {
      font-size: 0.85rem;
    }
  }

  .total-field-wrapper-reduced {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .total-field-wrapper-reduced > input {
    max-width: 6rem;
    min-width: 6rem;
  }

}

@media only screen and (min-width: 1280px) and (max-width: 1480px) {
  .summary-lines-wrapper > div:nth-child(2) {
    width: 22%;
  }

  .summary-lines-wrapper-reduced > div:nth-child(2) {
    width: 32%;
  }

  .summary-container-reduced {
    flex-wrap: wrap;
  }

  .summary-item {
    h2 {
      font-size: 1.7rem;
    };
    p {
      font-size: 0.88rem;
    }
    .info-icon-updated {
      font-size: 0.85rem;
    }
  }

  .summary-item-reduced {
    flex: 0 0 calc(50% - 10px);
    h2 {
      font-size: 1.74rem;
    };
    p {
      font-size: 0.88rem;
    }
    .info-icon-updated {
      font-size: 0.85rem;
    }
  }

  .total-field-wrapper-reduced {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .total-field-wrapper-reduced > input {
    max-width: 6rem;
    min-width: 6rem;
  }

}

@media only screen and (min-width: 1480px) and (max-width: 1580px) {
  .summary-lines-wrapper > div:nth-child(2) {
    width: 22%;
  }

  .summary-lines-wrapper-reduced > div:nth-child(2) {
    width: 26%;
  }

  .summary-item {
    h2 {
      font-size: 1.94rem;
    };
    p {
      font-size: 1.08rem;
    }
    .info-icon-updated {
      font-size: 1.05rem;
    }
  }

  .summary-item-reduced {
    h2 {
      font-size: 1.3rem;
    };
    p {
      font-size: 0.64rem;
    }
    .info-icon-updated {
      font-size: 0.55rem;
    }
  }

  .total-field-wrapper-reduced {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .total-field-wrapper-reduced > input {
    max-width: 7.84rem;
    min-width: 7.84rem;
  }

}

@media only screen and (min-width: 1580px) and (max-width: 1680px) {
  .summary-lines-wrapper > div:nth-child(2) {
    width: 22%;
  }

  .summary-lines-wrapper-reduced > div:nth-child(2) {
    width: 26%;
  }

  .summary-item {
    h2 {
      font-size: 2.04rem;
    };
    p {
      font-size: 1.18rem;
    }
    .info-icon-updated {
      font-size: 1.15rem;
    }
  }

  .summary-item-reduced {
    h2 {
      font-size: 1.4rem;
    };
    p {
      font-size: 0.74rem;
    }
    .info-icon-updated {
      font-size: 0.65rem;
    }
  }

  .total-field-wrapper-reduced {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .total-field-wrapper-reduced > input {
    max-width: 7.84rem;
    min-width: 7.84rem;
  }

}

@media only screen and (min-width: 1680px) {
  .summary-lines-wrapper > div:nth-child(2) {
    width: 18%;
  }

  .summary-lines-wrapper-reduced > div:nth-child(2) {
    width: 26%;
  }

  .summary-item {
    h2 {
      font-size: 2.14rem;
    };
    p {
      font-size: 1.28rem;
    }
    .info-icon-updated {
      font-size: 1.25rem;
    }
  }

  .summary-item-reduced {
    h2 {
      font-size: 1.54rem;
    };
    p {
      font-size: 0.78rem;
    }
    .info-icon-updated {
      font-size: 0.75rem;
    }
  }

  .total-field-wrapper-reduced {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .total-field-wrapper-reduced > input {
    max-width: 7.84rem;
    min-width: 7.84rem;
  }

  .tco-standard-view-sum-value-wrapper {
    margin-left: 4%;
  }

}