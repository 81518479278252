.d-flex{
    display: flex;
  }

  .content-section {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    padding: 55px 30px 25px;
  }
  .main-heading-section{
    margin-bottom: 90px;
  }
  .block-background {
    background: #F3F9FC;
    margin: 10px 0;
    padding: 15px;
    display: flex;
    align-items: horizontal;
    gap: 10px;
    height: 50%;
  }


  .main-title{
    font-size: 24px !important;
    line-height: 28px !important;
    margin-bottom: 5px;
  }

  .sub-title {
    //border-bottom: 1.5px solid #6BA5D2;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    //margin-bottom: 15px;
    //padding: 5px 0;
    color: #005D9D;
  }

  .success-story-sub-title {
    //border-bottom: 1.5px solid #6BA5D2;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    //margin-bottom: 15px;
    //padding: 5px 0;
    color: #005D9D;
  }

  .underline{
    border-bottom: 1.5px solid #6BA5D2;
    border-top: 1.5px solid #6BA5D2;
    margin: 5px 0px;
    padding: 5px 0;
  }

  .title-text {
    font-size: 8px;
    line-height: 13px;
    margin:0;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .colored-text{
    color: #005D9D;
  }

  .desc-text {
    font-size:7px; 
    margin:0;
    margin-bottom: 10px;
    padding: 1px;
    color: #646464;
  }
  .report-section{
    width: 50%;
  }

  .success-story-section{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .report-block{
    display: flex;
    //flex-flow: row;
    row-gap: 10px;
    flex-direction: row;
    align-items: horizontal;
    //padding-left: 10px;
    // margin-bottom: 20px;
  }
  .block-item{
    padding-bottom: 5px;
  }

  .left-column {
    flex: 1;
    // display: flex;
    flex-direction: column;
  }
  
  .right-column {
    flex: 1;
    // display: flex;
    flex-direction: column;
  }

  .footer-text{
    border: 0 !important; 
    margin: 5px auto;
    text-align: center;
    width: 70%;
  }

  .methodolgy-sec-minimized {
    p {
      font-size: 7px;
      line-height: 10px;
      margin-bottom: 2.5px;
      padding-bottom: 8px;

      &.title{
        color: #005B9B;
        font-size: 9px;
        font-weight: bold;
        text-align: center;
      }
    }

    .bubble-sec {
      
      font-size: 8px;
      text-align: right;

      span{
        background-color: #F0FAFF;
        border-radius: 15px;
        display: inline-block;
        padding: 2.5px 10px;
      }
    }
    
  }

  .methodolgy-sec {
    p {
      font-size: 8px;
      line-height: 12px;
      margin-bottom: 2.5px;
      padding-bottom: 8px;

      &.title{
        color: #005B9B;
        font-size: 9px;
        font-weight: bold;
        text-align: center;
      }
    }

    .bubble-sec {
      
      font-size: 8px;
      text-align: right;

      span{
        background-color: #F0FAFF;
        border-radius: 15px;
        display: inline-block;
        padding: 2.5px 10px;
      }
    }
    
  }
  .banner-container{
    height: 60px !important;
    margin: 10px 0px
  }
  .banner-img{
    object-fit: contain;
  }

  .content-section {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    padding: 25px 30px;

    .flex-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .mb-30 {
      margin-bottom: 30px;
    }

    

    .report_title {
      font-size: 40px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 15px;
    }

    .title_1 {
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
    }

    .title_right {
      color: #3C7DCC;
      font-size: 10px;
      font-weight: 400;
      line-height: 11px;
      text-align: right;
      margin-bottom: 15px;
    }

    .p-table {
      justify-content: space-between;

      >div{
        width: 47.5%;
      }
    }

    .footer-text{
      border: 0 !important; 
      margin: 5px auto;
      text-align: center;
      width: 70%;
    }

    .pricing-table{
      background-color: #F0FAFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px;
      width: calc(100% - 10px);

      .highlight {
        border-bottom: 1px solid #6BA6D2;
        color: #005B9B;
        font-size: 14px;
        font-weight: 600;
        padding: 3px 0 4px;
        margin-bottom: 10px;
      }

      ul{
        list-style: none;
        padding: 0;
        margin: 0;

        li{
          margin: 7.5px 0;
          

          &.table-total {
            align-items: center;
            border-top: 1px solid #6BA6D2;
            color: #005B9B;
            margin-top: 0;
            padding-top: 10px;
                          
            .t-sub-title{
              color: #3C7DCC;
              font-size: 8px;  
            }
  
            .t-amount {
              font-size: 10.5px;
            }

            .t-title{              
              font-size: 10px
            }
          }

          >div{
            padding-left: 5px;
          }

          .t-title{
            
            font-size: 9px;
            line-height: 10px;
            font-weight: 600;
          }

          .t-sub-title{
            color: #666;
            font-size: 7px;
            line-height: 9px;
            font-weight: 400;

          }

          .t-amount {
            font-size: 9px;
            font-weight: 400;
            line-height: 10px;
            min-width: 55px;
            text-align: right;
          }

          
          
        }
      }
    }
  }


  .pdf{
    font-family:  -apple-system, BlinkacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    color: #191919;
    letter-spacing: 0.01px;
    margin:0;
    padding: 0;
  
    p{
      margin: 0;
    }
  
    .d-flex{
      display: flex;
    }
  
    .vh-center{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .link{
      border-bottom: 1px solid #0078D4;
      color: #0078d4;
      cursor: text !important;
      pointer-events: none;
    }
  
    .content_section {
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
      padding: 25px 30px;
  
      .flex_col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
  
      .mb-30 {
        margin-bottom: 30px;
      }
      
      
  .industry-block{
    display: flex;
    height: 100%;
    align-items: horizontal;
    background: #F3F9FC;
  
    .industry-content-section{
      background: #F3F9FC;
      width: 100%;
      padding: 15px;
    }
    .industry-img-section{
      width: 100%;
      height: 100% !important;
      // object-fit: cover;
      display: block;
      max-height: none;
    }

    .industry-img{
      width: 250px;
      height: 100% !important;
      object-fit: cover !important; 
    }
  
    .industry-desc-section{
      border-top: 1.5px solid #6BA5D2;
      margin: 5px 0px;
      padding: 5px 0;
    }
  
  }
      
  
      .report_title {
        font-size: 40px;
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 15px;
      }
  
      .title_1 {
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
      }
  
      .title_right {
        color: #3C7DCC;
        font-size: 10px;
        font-weight: 400;
        line-height: 11px;
        text-align: right;
        margin-bottom: 15px;
      }
  
      .p-table {
        justify-content: space-between;
  
        >div{
          width: 47.5%;
        }
      }
  
      .footer-text{
        border: 0 !important; 
        margin: 5px auto;
        text-align: center;
        width: 70%;
      }
  
      .pricing-table{
        background-color: #F0FAFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px;
        width: calc(100% - 10px);
  
        .highlight {
          border-bottom: 1px solid #6BA6D2;
          color: #005B9B;
          font-size: 14px;
          font-weight: 600;
          padding: 3px 0 4px;
          margin-bottom: 10px;
        }
  
        ul{
          list-style: none;
          padding: 0;
          margin: 0;
  
          li{
            margin: 7.5px 0;
            
  
            &.table-total {
              align-items: center;
              border-top: 1px solid #6BA6D2;
              color: #005B9B;
              margin-top: 0;
              padding-top: 10px;
                            
              .t-sub-title{
                color: #3C7DCC;
                font-size: 8px;  
              }
    
              .t-amount {
                font-size: 10.5px;
              }
  
              .t-title{              
                font-size: 10px
              }
            }
  
            >div{
              padding-left: 5px;
            }
  
            .t-title{
              
              font-size: 9px;
              line-height: 10px;
              font-weight: 600;
            }
  
            .t-sub-title{
              color: #666;
              font-size: 7px;
              line-height: 9px;
              font-weight: 400;
  
            }
  
            .t-amount {
              font-size: 9px;
              font-weight: 400;
              line-height: 10px;
              min-width: 55px;
              text-align: right;
            }
  
            
            
          }
        }
      }
    }
  
    .chart_sec {
      display: flex;
  
      img {
        width: 250px;
      }
  
      .price_text {
        font-size:30px; 
        line-height: 30px;
        font-weight:bold;
        margin: 10px 0;
      }
  
      .title_text {
        font-size: 12px;
        line-height: 15px;
        margin:0;
        margin-bottom: 15px;
      }
  
      .desc_text {
        font-size:8px;
        line-height: 13px; 
        margin:0;
        margin-bottom: 10px;
        padding: 1px;
      }
    }
  
    a{
      border-bottom: 1px solid #0078D4;
      padding-bottom: 1px;
    }
  
    .main_title{
      font-size: 20px !important;
      line-height: 20px !important;
      margin-bottom: 5px;
    }
  
    .main_title_sub{
      font-size: 14px;
      line-height: 28px !important;
      margin-bottom: 25px;
    }
  
    .sub_title {
      border-bottom: 2px solid #F6F6F6;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px;
      padding: 5px 0;
    }
    
    .main_sec {
      display: flex;
  
      .content_sec {
        p{
          font-size: 10px;
          line-height: 13px;
          margin-bottom: 20px;
        } 
  
        .title_sec {
          display: flex;
          align-items: center;
          padding: 0 20px;
  
          .main_title {
            margin-bottom: 50px;
          }
        }      
  
        .formated_content_sec {
          background-color: rgba(242, 242, 242, 0.7);
          display: flex;
          align-items: center;
          padding: 0 20px;
  
          .formated_value{
            color: #0078D4;
            font-size: 32px;
            margin-bottom: 15px;
            margin-top: 0;
          }
  
          .formated_value_desc{
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            margin-bottom: 35px;
          }       
  
        }      
      }    
    }
  
    .marker{
      display: inline-block;
      margin: 0 5px !important;
      border-radius: 1.5rem;
      width: 12px  !important;
      height: 12px  !important;
    }
  
    th{
      text-align: left;
    }
  
    th, td{
      margin: 0 2px;
      padding: 8px 0px;
    }
  
    .section_1 {
      padding: 15px 20px 0;
      width:100%;
    }
  
    .section_2 {
      padding: 0 20px 15px;
      width:100%;
  
      .conclusion {
        font-size: 10px;
      }
    }
  
    .one_pager{
      padding-top: 45px;
  
      .main_title{
        font-size: 20px !important;
        line-height: 22px !important;
        margin: 5px 0;
        margin-top: 5px;
      }
  
      .sub_title{
        border-bottom: 1px solid #6BA6D2;
        color: #005B9B;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
      }
  
      .main_title_sub{
        color: #005B9B;
        font-size: 13px;
        line-height: 14px !important;
      }
  
      .methodolgy-sec {
        p {
          font-size: 8px;
          line-height: 12px;
          margin-bottom: 2.5px;
  
          &.title{
            color: #005B9B;
            font-size: 9px;
            font-weight: bold;
            text-align: center;
          }
        }
  
        .bubble-sec {
          
          font-size: 8px;
          text-align: right;
  
          span{
            background-color: #F0FAFF;
            border-radius: 15px;
            display: inline-block;
            padding: 2.5px 10px;
          }
        }
        
      }
  
      .methodolgy-sec-minimized {
        p {
          font-size: 7px;
          line-height: 10px;
          margin-bottom: 2.5px;
  
          &.title{
            color: #005B9B;
            font-size: 9px;
            font-weight: bold;
            text-align: center;
          }
        }
  
        .bubble-sec {
          
          font-size: 8px;
          text-align: right;
  
          span{
            background-color: #F0FAFF;
            border-radius: 15px;
            display: inline-block;
            padding: 2.5px 10px;
          }
        }
        
      }
  
      .sus-savings {
        // background-color: #F0FAFF;
        background-color: #c4ffc3;
        margin-top: 5px;
        padding: 5px 10px;
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        >div:first-child {
          // background-color: red;
          width: 7%;
          img {
            width: 24px;
            object-fit: contain;
          }
        }
        >div:last-child {
          width: 93%;
        }
      }
      
    }
    
} 

.price-lines {
  display: inline-grid;
  grid-template-columns: 50px auto;
}

.price-line {
  display:contents;
}
.price-line-title {
  grid-column: 1;
  text-align: right;
}

.price-line-description {
  grid-column: 2;
  padding-left: 0.8rem;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 2px;
}

.price-lines > div:nth-child(odd) > div{
  background-color:#F5F3F3;
}

.price-lines > .price-line.total > div{
  background-color: #3d3d3d ;
  color: white;
}
.price-lines > .price-line.total-all > div{
  background-color: #555555 ;
  color: white;
}
