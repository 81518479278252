.methodology-page-wrapper {
  ol {
    counter-reset: list;
    > li {
      list-style: none;
      &:before {
        counter-increment: list;
      }
      
    }
  }
  > ol {
    padding: 0;
    > li {
      &:before {
        content: "(" counter(list) ") ";
      }
      > ol > li:before {
        content: "(" counter(list, lower-alpha) ") ";
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .methodology-page-wrapper {
    font-size: 1.4rem;
  }
}