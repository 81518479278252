.chatbot-flex {
  display: flex;
}

.ms-ContextualMenu-Callout {
  padding: 0.4rem;
  border-radius: 5px;
  background: linear-gradient(218deg, #FFF 0%, #F1F0EF 100%);
  background: white;
  box-shadow: 0px 0px 1.5399999618530273px 0px rgba(0, 0, 0, 0.12), 0px 0.7699999809265137px 1.5399999618530273px 0px rgba(0, 0, 0, 0.14);
}

.chatbot-messages {
  position: relative;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
      background-color: rgba(117, 117, 122, 0.3);
      outline: 1px solid rgb(117, 117, 122, 0.5);
      border-radius: 10px;
  }
}

.chatbot-messages-reduced {
  position: relative;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
      background-color: rgba(117, 117, 122, 0.3);
      outline: 1px solid rgb(117, 117, 122, 0.5);
      border-radius: 10px;
  }
}

.input-area .ms-TextField{
  box-sizing: border-box;
  border: 1px solid #A7A7A7;
  border-radius: 5px;
  padding: 1px;
  background: white;
}

.input-area.input-area-expanded .ms-TextField{
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.input-area .ms-TextField-fieldGroup{
  border: none;
  height: 2.7rem;
}

.input-area .ms-TextField-suffix{
  cursor: pointer;
  background: #FFFFFF;
  // padding-left: 0px;
}

.input-area .ms-TextField-fieldGroup::after {
  border: none;
}

.input-area .ms-TextField-field{
  font-size: 1rem;
  color: #A7A7A7;
}

.input-area input.ms-TextField-field{
  font-size: 1rem;
  color: #A7A7A7;
}

.input-area textarea {
  color: #A7A7A7;

  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
      background-color: rgba(117, 117, 122, 0.3);
      outline: 1px solid rgb(117, 117, 122, 0.5);
      border-radius: 10px;
  }
}

// .ms-Modal{
//   left: 0;
//   justify-content: flex-start;
// }

// .ms-Overlay {
//   background-color: transparent;
// }

// .custom-modal {
  // .ms-Dialog-main {
  //   left: 0;
  //   margin-left: 4rem;

  // }
// }

// Responsiveness

@media only screen and (max-width: 1320px) {
  .chatbot-messages{
    height: 82%;
    position: relative;
  }
  .footer-container-reduced{
    height: 18%;
  }
  .chatbot-messages-reduced{
    height: 73%;
    position: relative;
  }
  .footer-container{
    height: 27%;
  }
  .footer-disclaimer{
    font-size: 0.54rem;
  }
}

