input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 820px) {
    .product-container-wrapper {
        flex-wrap: wrap;
        justify-content: center;
        // box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
        // border-radius: 6px;
        // padding: 1rem 0rem 1rem 1rem;
        width: 100%;
    }
    .product-container-wrapper > div:first-child {
        flex-wrap: wrap;
        width: 100%;
        > div {
            width: 100%;
        }
    }

    .product-container-wrapper > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        > div {
            width: 100%;
        }
    }

    .product-box-inner-container-mobile {
        > div:first-child {
            display: flex;
            flex-wrap: wrap;
            > div {
                margin-bottom: 1rem;
            }
        }
        > div:last-child {
            margin-bottom: 1rem;
            > input {
                width: 90%;
            }
            > i {
                font-size: 1.5rem;
            }
        }
    }
}