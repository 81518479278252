@media only screen and (max-width: 820px) {
  .get-in-touch-wrapper {
    position: fixed;
    z-index: 3;
    height: 4rem;
    width: unset;
    right: 0;
    top: 18rem;
    max-width: unset;
    left: unset;
    transform: unset;
    button {
      min-width: unset;
      width: unset;
      border-radius: 0.5rem 0 0 0.5rem;
      padding: 0 1.2rem;
      margin-right: 0;
      i {
        margin: 0;
        font-size: 1.2rem;
        height: 1.2rem;
        line-height: 1.6rem;
      }
      .ms-Button-textContainer {
        width: 0;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        font-size: 1.6rem;
        line-height: 2rem;
        .ms-Button-label {
          width: 9.2rem;
          margin-left: 0.8rem;
        }
      }
      .show-content {
        width: 10rem;
        opacity: 1;
      }
    }
  }
}
.get-in-touch-wrapper .ms-Button-textContainer .ms-Button-label {
  margin: 0;
}